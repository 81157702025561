import * as React from "react";
import { FC } from "react";
import classes from "./exhibition-list.module.css";

export const ExhibitionList: FC<{ items: string[] }> = ({ items }) => (
  <ul className={classes.list}>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
);
