import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import {
  Heading,
  HEADING_CLASS,
  HEADING_LEVEL
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import { ExhibitionList } from "../components/exhibitions/exhibition-list";
import { exhibitions } from "../components/exhibitions/localization";

export const ExhibitionsEndpoint: FC<ILocalizedPage> = _props => {
  const title = useLocalized("exhibitions_title");
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <p>
        <>
          <strong>{useLocalized("exhibitions_name")}</strong>
          {useLocalized("exhibitions_text_1")}
          <br />
          {useLocalized("exhibitions_text_2")}
        </>
      </p>
      <Heading level={HEADING_LEVEL.H2} theme={HEADING_CLASS.TEXT}>
        {useLocalized("exhibitions_title_2")}
      </Heading>
      <ExhibitionList items={useLocalized("exhibitions_part_1")} />
      <p>
        <>
          {useLocalized("exhibitions_text_3")}
          <br />
          {useLocalized("exhibitions_text_4")}
        </>
      </p>
      <Heading level={HEADING_LEVEL.H2} theme={HEADING_CLASS.TEXT}>
        {useLocalized("exhibitions_title_3")}
      </Heading>
      <ExhibitionList items={useLocalized("exhibitions_part_2")} />
      <p>
        <>{useLocalized("exhibitions_text_5")}</>
      </p>
    </>
  );
};
