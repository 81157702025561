import * as React from "react";
import { FC } from "react";
import { ExhibitionsEndpoint } from "../../../entrypoints/exhibitions";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.deCH}
    entrypoint={"exhibitions"}
    page={"ausstellungen"}
  >
    <ExhibitionsEndpoint lang={LANG.deCH} />
  </DefaultLayout>
);
